<template>
    <div class="content-view">
        <div class="form-section">
            <div class="container-fluid">
                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                <div class="row row-head lined">
                    <div class="col-xs-12">
                        <h5>Manage Brivo Access</h5>
                        <p>Connect to Brivo to import your payments.</p>
                    </div>
                </div>

                <div class="row lined" v-show="!loaded">
                    <div class="col-xs-12 text-center">
                        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>

                    </div>
                </div>

                <div class="row lined" v-show="!brivoConfigured && loaded">
                    <div class="col-xs-6">
                        <strong>Connect To Brivo</strong>
                        <p>You are not currently connected to Brivo. Click the button to set it up.</p>
                    </div>
                    <div class="col-xs-6 text-right">
                        <button @click="connect"  alt="" class="w-button primary-btn" style="width: 200px;">Connect To Brivo</button>
                    </div>
                </div>


                <div class="row">
                    <div class="col-xs-12">
                        <a class="btn btn-dark" @click="save">
                            <i class="fa fa-check"></i>
                            &nbsp;&nbsp;Save Settings</a>
                        <span v-show="isLoading($options.name)" >
                                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                            </span>
                    </div>
                </div>


            </div>
        </div>


    </div>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import Dropdown from '../assets/Dropdown.vue';

    export default {
        name: "BrivoSettings",
        data() {
            return {
                category: 'brivo',
                brivoConfigured: false,
                clientID: '',
                authHeader: '',
                apikey: '',
                loaded: ''


            }
        },
        components:{
            Modal,
            Loader,
            Dropdown
        },
        created(){
            //this.fetchData();
            EventBus.$on('brivoConnection', this.fetchData);
        },
        destroyed(){
            EventBus.$off('brivoConnection', this.fetchData);
        },
        computed:{

        },
        filters:{
            formatDate(date){
                return moment(date).format('MM/DD/YYYY HH:mm:ss')
            }
        },
        methods:{

            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.selected = {};
            },
            fetchData(){

                api.get(this, api.SETTINGS_GET_BRIVO).then(r => {
                    this.clientID = r.clientID;
                    this.authHeader = r.authHeader;
                    this.apikey = r.apikey;
                    this.loaded = true;
                });
            },
            disconnect(){
                this.loaded = false;
                api.get(this, api.SETTINGS_QB_DISCONNECT).then(r => {
                    this.fetchData();
                });
            },
            connect(c){
               window.open("https://auth.brivo.com/oauth/authorize?response_type=code&client_id=886d02a3-51ad-4068-bd6a-41c8ccdb10e1&state=2", "", "width=600,height=400");
            },
            save(){
                var save = {
                    category: this.category,
                    data: this.settings
                };
                api.post(this, api.SETTINGS_SAVE, save).then(r => {
                    this.successSet(this.$options.name, "Settings Updated");
                });
            },
        }
    }
</script>

<style>
    .subdued {
        color: #a5adb4;
        line-height: 18px;
    }
</style>